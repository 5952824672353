// app global css in SCSS form

@font-face {
  font-family: 'Benzin-Semibold';
  src: url('../assets/fonts/Benzin/Benzin-Semibold.ttf');
  src: local('Benzin-Semibold'), local('Benzin-Semibold'),
  url('../assets/fonts/Benzin/Benzin-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Benzin-Bold';
  src: url('../assets/fonts/Benzin/Benzin-Bold.ttf');
  src: local('Benzin-Bold'), local('Benzin-Bold'),
  url('../assets/fonts/Benzin/Benzin-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
  src: local('Roboto-Regular'), local('Roboto-Regular'),
  url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
  src: local('Roboto-Medium'), local('Roboto-Medium'),
  url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

#q-app {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul {
  padding: 0;
  list-style-type: none;
}
